*,*::before,*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
body{
    /* background-color: #f7f7f7 !important; */
    background-color: #e7e7e7 !important;
}

button{
    cursor: pointer !important;
}

.p-tabview .p-tabview-panels{
    padding: 20px 0 10px 0 !important;
}

.p-tabview .p-tabview-nav-btn.p-link {
    display: none !important;
    opacity: 0 !important;
}

.p-chart{
    cursor: pointer;
}

/* .p-datatable tbody tr:last-child{
    background: #F8F9FA !important;
} */