.header{
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 0 0 0.6rem 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.header_sub_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;
}

.client_name,.service_name{
    border: none;
    outline: none;
    font-size: 1rem;
    font-family: 'Montserrat',sans-serif;
    /* background-color: #212135; */
    background-color: #fff;
    /* color: white; */
    color: #343A40;
}

.header_avatar{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
}
.header_avatar .avatar{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.avatar_icon{
    /* color: white; */
    color: #343A40;
    font-size: 0.7rem;
}

.dropdown{
    display: none;
    position: absolute;
    bottom: -6.5rem;
    right: 0;
    width: 11rem;
    height: 7rem;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.488) 0px 7px 29px 0px;
    border-radius: 0.2rem;
    padding: 1rem 1rem 1rem 1rem;
    transition: all 0.3s;
}

.dropdown.show{
    display: block;
    transition: all 0.3s;
    z-index: 100;
}

.dropdown_sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.user{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.user_icon{
    /* color: #212135; */
    color: #343A40;
    font-size: 1rem;
}

.dropdown_client_name{
    font-size: 1rem;
    font-family: 'Montserrat',sans-serif;
    /* color: #212135; */
    color: #343A40;
}

.underline{
    width: 100%;
    height: 0.1rem;
    /* background-color: #212135; */
    background-color: #343A40;
}

.logout_btn{
    border: none;
    outline: none;
    background-color: #696CFF;
    color: white;
    border-radius: 0.3rem;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    transition: all 0.2s;
}

.logout_icon{
    font-size: 1rem;
    color: white;
}

.logout_btn:hover{
    background-color: #5457f8;
}
.logout_btn:active{
    transform: scale(1.1);
}

.menu{
    display: none;
}

@media screen and (max-width:700px){
    .menu{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu_icon{
        font-size: 1rem;
        /* color: white; */
        color: #343A40;
        cursor: pointer;
    }
}