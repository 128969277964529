.main {
    display: grid;
    grid-template-columns: 15rem auto;
    gap: 1rem;
    transition: all 0.3s;
}

.sidebar {
    position: fixed;
    min-height: 100dvh;
    max-width: 15rem;
    width: 100%; 
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    z-index: 99;
    padding: 1rem 0 2rem 0;
    transition: all 0.3s;
}

.sidebar::-webkit-scrollbar {
    width: 3px; 
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #E5E5E5;
    border-radius: 4px;
}

.sidebar_header{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.sidebar_logo{
    width: 50px;
    height: auto;
    object-fit: contain;
}

.sidebar_icon{
    position: absolute;
    right: -0.5rem;
    top: 1rem;
    transform: translate(0,0);
}
.sidebar_icon .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #696CFF;
    cursor: pointer;
    /* box-shadow: 0 2rem 2rem rgba(0,0,0,0.6); */
}

.arrow_icon{
    color: white;
}


.dashboard_text{
    /* color: white; */
    color: #343A40;
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
}

.container {
    grid-area:1/2/2/-1;
    margin-right: 1rem;
    margin-bottom: 1rem;
    min-height: 100vh;
    background-attachment: fixed;
    overflow-x: hidden;
    transition: all 0.3s;
}

.sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem 2rem 1rem;
    z-index: 99;
    overflow: hidden;
    border-radius:0.3rem;
    margin-top: 1rem;
    /* background-color:#212135; */
    background-color:#fff;
}

.form{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.search_btn{
    border: none;
    outline: none;
    background-color: rgb(0, 216, 0);
    padding: 0.4rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    cursor: pointer;
}

.title_container{
    width: 100%;
    border: 1px solid #3333337c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
}

.title_sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
}

.title_sub_container h3{
    font-size: 1rem;
    font-family: 'Montserrat',sans-serif;
    color: #333;
}
.title_sub_container i{
    font-size: 1rem;
    color: #333;
}

.table_container {
    width: 100%;
    overflow: hidden;
}

.table_sub_container {
    width: 100%;
    height: 500px;
}

.main.short{
    display: grid;
    grid-template-columns: 3rem auto;
    gap: 1rem;
    transition: all 0.3s;
}
.sidebar_header.short{
    display: none;
}
.sidebar.short {
    max-width: 3rem;
}

@media screen and (max-width:900px){
    .form{
            display: flex;
            justify-content:center;
            align-items: flex-start;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
    }
    .service{
        width: 100%;
    }
    .partner{
        width: 100%;
    }
    .client{
        width: 100%;
    }
    .start_date{
        width: 100%;
    }
    .end_date{
        width: 100%;
    }
    .main {
        grid-template-columns: 8rem auto;
    }
    .sidebar {
        max-width: 8rem;
    }
    .dashboard_text{
        color: #343A40;
        font-size: 1.3rem;
        font-family: 'Montserrat',sans-serif;
        font-weight: 400;
    }

    /* .sidebar_header{
        display: none;
    } */
}

@media screen and (max-width: 700px) {
    .main {
        grid-template-columns: 0rem auto;
        gap: 0rem;
    }
    .sidebar {
        display: none;
    }
    .sidebar_header {
        display: none;
    }
    .main.short{
        display: grid;
        grid-template-columns: 0rem auto;
        gap: 0rem;
        transition: all 0.3s;
    }
    .sidebar_header.short{
        display: none;
    }
    .sidebar.short {
        max-width: 0rem;
    }
    .container {
        width: 100dvw;
        min-height: 100vh;
        background-attachment: fixed;
        overflow-x: hidden;
        transition: all 0.3s;
    }
}



/* Search By Click Id Styles... */

.click_id_form{
    background-color: #E7E7E7;
    padding: 15px 10px 15px 10px;
    width: 100%;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 10px;
}

.modal_content{
    padding: 20px 0 20px 0;
}

.flex_container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
}

.add_btn{
    border: none;
    outline: none;
    /* background-color: rgb(0, 216, 0); */
    background-color: #696CFF;
    padding: 0.4rem;
    font-size:1rem;
    color: white;
    border-radius: 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.table_container {
    width: 100%;
}

.table_sub_container {
    width: 100%;
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x:auto;
    margin-bottom: 50px;
    width: 100%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    color: #fff;
    /* background-color: #212135; */
    background-color: #fff;
  }
  
  .th {
    /* background-color: #000000; */
    background-color: #F8F9FA;
    border: 1px solid #555;
    padding: 10px;
    text-align: left;
    color: #343A40;
  }

  /* .tr_th{
    position: sticky;
    top: 0;
    z-index: 99;
  } */
  
  .td {
    border: 1px solid #555;
    padding: 10px;
    color: #343A40;
  }


  .box{
    width: 100%;
    /* border: 1px solid rgba(255,255,255,0.6); */
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 0.3rem;
    padding: 0.5rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box_title {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    /* color: #fff; */
    color: #343A40;
    margin: 0; 
  }
  