.title_container {
    width: 100%;
    /* border: 1px solid rgba(255,255,255,0.6); */
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 0.3rem;
    padding: 0.5rem; 
  }
  
  .title_sub_container {
    width: 100%;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    gap:0.5rem;
  }
  
  .title_sub_container h3 {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    /* color: #fff; */
    color: #343A40;
    margin: 0; 
  }
  
  .title_sub_container i {
    font-size: 1rem;
    color: #fff;
  }