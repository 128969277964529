.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    padding: 1rem 3rem 1rem 3rem;
    height: 100dvh;
    background-color: #E7EFFF;
}
.sub_container{
    width:100%;
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:1rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow:0 1rem 2rem rgba(0,0,0,0.7);
}
.grid_container{
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.grid_item_1{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.animation_container{
    width:40rem;
    height:30rem;
}
.animation{
    width:100%;
    height:100%;
    object-fit: contain;
}
.grid_item_2{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.heading_container{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.heading{
    font-size: 2.5rem;
    font-family:"Montserrat",sans-serif;
    font-weight:bolder;
    color:#333;
}

.form_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form_sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem;
}

.input_form input{
    width: 20rem;
    height: 4rem;
    font-family: 'Montserrat',sans-serif;
    border: none;
    outline: none;
    border-bottom: .2rem solid #3333338a;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    padding-right:1rem;
    background-color: transparent;    
}

.input_form input::placeholder{
    color: #333;
    letter-spacing: .1rem;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
}

.input_form input:focus{
    border-color:#333 ;
}

.input_form{
    position: relative;
}
.input_form i{
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    font-size: 1.3rem;
    color: #333;
}

.icon{
    cursor: pointer;
    transition: all 0.2s;
}
.icon:active{
    transform: scale(1.1);
}

.login_button{
    padding: 0.3rem 1rem 0.3rem 1rem;
    display: flex;
    /* width: 9rem; */
    justify-content: center;
    align-items: center;
    gap:0.3rem;
    border: none;
    outline: none;
    background-color: #014C97;
    border-radius: 0.3rem;
    font-size:1.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight:400;
    color: white;
    transition: all 0.2s;
}

.login_button:hover{
    background-color: #002a55;
}

.login_button:active{
    transform: scale(1.1);
}

.login_button i{
    margin-left: 0.4rem !important;
}

@media screen and (max-width:1200px){
    .animation_container{
        width:30rem;
        height:20rem;
    }
   
}

@media screen and (max-width:950px){
    .grid_container{
        width: 100%;
        display:grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:100%;
        padding: 1rem 0.8rem 1rem 0.8rem;
        height: 100dvh;
        background-color: #E7EFFF;
    }
    .animation_container{
        width:20rem;
        height:15rem;
    }
}

@media screen and (max-width:400px){
    .heading{
        font-size: 2rem;
        font-family:"Montserrat",sans-serif;
        font-weight:bolder;
        color:#333;
    }
}